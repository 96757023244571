<template>
  <section
    class="sub container-fluid m-0 p-0 d-flex flex-lg-row flex-column bg-dark text-light"
  >
    <div
      class="sub-1 col d-flex flex-column order-lg-1 order-2 justify-content-evenly align-items-center py-5"
    >
      <h2 class="text-primary">Votre talent nous intéresse</h2>
      <p class="text-center">
        Faites partie d’une équipe dynamique pour utiliser vos talents et
        expertises.
      </p>
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-lg btn-outline-primary rounded-pill px-4 d-flex align-items-center"
        data-bs-toggle="modal"
        data-bs-target="#modalRejoindre"
      >
        <IcoBrief />
        <div class="ps-2">Postulez !</div>
      </button>
      <ModalRejoindre />
    </div>
    <div
      class="sub-2 col d-flex flex-column order-lg-2 order-1 justify-content-center align-items-center"
    >
      <img
        src="../../assets/illustration/Illus-Recrutement-MK-CO.svg"
        alt=""
        srcset=""
      />
    </div>
  </section>
</template>

<script>
import ModalRejoindre from "../visiteur/ModalRejoindre.vue";
import IcoBrief from "vue-material-design-icons/BriefcaseSearch.vue";

export default {
  name: "RejoindreMK-CO",
  components: {
    ModalRejoindre,
    IcoBrief,
  },
  // data() {
  //   return {
  //     form: {
  //       full_name_candidat_mk_co: "",
  //       tel_candidat_mk_co: "",
  //       email_candidat_mk_co: "",
  //       message_candidat_mk_co: "",
  //     },
  //     files: [{ name: "", file: null }],
  //     loading: false,
  //     submitted: false,
  //   };
  // },
  // validations: {
  //   form: {
  //     full_name_candidat_mk_co: { required },
  //     tel_candidat_mk_co: { required },
  //     email_candidat_mk_co: { required, email },
  //   },
  // },
  // methods: {
  //   addField() {
  //     this.files.push({ name: "", file: null });
  //   },
  //   removeField(index) {
  //     this.files.splice(index, 1);
  //   },
  //   changeFile(e, index) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);
  //     reader.onload = (event) => {
  //       const base64 = event.target.result;
  //       this.files[index].file = base64;
  //     };
  //     //this.files[index].file = e.target.files[0];
  //   },
  //   closeModalRejoindre() {
  //     this.submitted = false;
  //   },
  //   confirm() {
  //     this.submitted = true;
  //     this.$v.$touch();
  //     if (this.$v.$invalid) {
  //       return;
  //     }
  //     this.loading = true;
  //     console.log("call");
  //     addCandidatMk(this.form, this.files).then((result) => {
  //       if (result.data.error) {
  //         this.loading = false;
  //         error(result.data.error, "");
  //       } else {
  //         this.loading = false;
  //         this.$refs.close.click();
  //         success("Candidature envoyé");
  //       }
  //     });
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.sub {
  height: auto;
}

.sub-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../../assets/img/img-talent.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  img {
    width: 45%;
  }
}
</style>
