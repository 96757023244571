<template>
  <section class="d-flex flex-lg-row flex-column bg-secondary">
    <div class="m-2 d-flex justify-content-center align-items-center">
      <div class="container mt-3">
        <h2 class="text-primary mb-3">Nous avons séléctionné pour vous</h2>
        <!-- <div class="row row-cols-2 g-4">
          <div class="col" v-for="(article, i) in articles" :key="i">
            <div class="card border-0 h-100">
              <img :src="article.img" class="card-img-top" alt="..." />
              <div
                class="card-body bg-dark text-light d-flex flex-column justify-content-between"
              >
                <div>
                  <h5 class="card-title text-primary">{{ article.title }}</h5>
                  <p class="card-text">
                    {{ article.text }}
                  </p>
                </div>
                <p class="card-text text-end">
                  <small class="text-muted"> {{ article.date }}</small>
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div>
          <div
            class="card shadow rounded-end border-0 my-4"
            v-for="(article, i) in articles"
            :key="i"
          >
            <div class="row g-0">
              <div class="col-4 img-fill position-relative">
                <img
                  :src="article.img"
                  class="img-fluid rounded-start position-absolute"
                  alt="..."
                />
              </div>
              <div class="col-8">
                <div class="card-body">
                  <h5 class="card-title text-primary">{{ article.title }}</h5>
                  <p class="card-text">
                    {{ article.text }}
                  </p>
                  <p class="card-text text-end">
                    <small class="text-muted">{{ article.date }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-dark container-fluid d-flex flex-column align-items-center justify-content-start"
    >
      <h2 class="text-center my-4 text-light">A ne pas manquer</h2>
      <Carousel />
      <!-- <div class="h-100 d-flex align-items-center justify-content-center">
        <img
          class="img-illus"
          src="../../assets/illustration/Illus-Article.svg"
          alt=""
          srcset=""
        />
      </div> -->
    </div>
  </section>
</template>

<script>
import Carousel from "../visiteur/Carousel.vue";

export default {
  name: "ArticleRecent",
  components: {
    Carousel,
  },
  data() {
    return {
      articles: [
        {
          img: require("../../assets/img/bg-img-5.jpg"),
          title: "Suspendisse diam, nunc sit augue",
          text: "Maxime harum labore obcaecati ipsa hic explicabo adipisci ut porro.",
          date: "22/03/2022",
        },
        {
          img: require("../../assets/img/bg-img-2.jpg"),
          title: "Deleniti nobis soluta voluptatibus facilis?",
          text: "Porta non porta turpis senectus et integer massa tortor. Odio mattis quis nem neque scelerisque feugiat. Turpis tortor.",
          date: "15/12/2021",
        },
        {
          img: require("../../assets/img/bg-img-4.jpg"),
          title: "Commodi necessitatibus dolore repellat nemo.",
          text: "Fuga deleniti nesciunt vero officiis asperiores dolor! Tenetur, ea minima!",
          date: "30/01/2022",
        },
        {
          img: require("../../assets/img/bg-img-6.jpg"),
          title: "Commodi necessitatibus dolore repellat nemo.",
          text: "Ta turpis senectus et integer massa tortor. Odio mattisres dolor! Tenetur, ea minima!",
          date: "03/11/2022",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

// section {
//   height: auto;
//   background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
//     url("../../assets/img/bg-img-9.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
// }

.img-illus {
  width: 100%;
  height: 85%;
}

// .articleRecent {
//   background-color: rgba(0, 0, 0, 0.5);
// }

.card {
  width: 100%;
  height: 100%;
  background-color: $dark;
  color: $light;

  // .card-img-top {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }

  cursor: pointer;
  transition: 250ms;

  h5 {
    transition: 250ms;
  }

  &:hover {
    background-color: $primary;
    color: $dark;

    h5 {
      color: darken($light, $amount: 50%) !important;
    }
  }

  .img-fill img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
