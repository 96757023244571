var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header h-screen"},[_c('div',{staticClass:"contact px-4 container-fluid d-lg-flex justify-content-between align-items-center p-2 d-none text-muted nav-contact custom_transition"},[_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"col d-flex flex-fill align-items-center"},[_c('IcoLocalisation'),_c('small',{staticClass:"ms-2"},[_vm._v("II M A35 Antsakaviro 101-Antananarivo")])],1),_c('small',{staticClass:"col d-flex flex-fill align-items-center ps-4"},[_c('IcoTel'),_c('small',{staticClass:"ms-2"},[_vm._v("+261 32 11 888 95")])],1),_c('small',{staticClass:"col d-flex flex-fill align-items-center ps-4"},[_c('IcoMail'),_c('small',{staticClass:"ms-2"},[_vm._v("votre-objectif@mk-co.org")])],1)]),_c('small',{staticClass:"d-flex text-end"},[_c('a',{staticClass:"m-3 ms-0",attrs:{"href":"https://web.facebook.com/MKCOPartner","target":"_blank","rel":"noopener noreferrer"}},[_c('IcoFacebook')],1),_c('a',{staticClass:"m-3",attrs:{"href":"https://www.instagram.com/mkcopartner/","target":"_blank","rel":"noopener noreferrer"}},[_c('IcoInstagram')],1),_c('a',{staticClass:"m-3",attrs:{"href":"https://api.whatsapp.com/message/SYIFLTHVSMGOI1?autoload=1&app_absent=0","target":"_blank","rel":"noopener noreferrer"}},[_c('IcoWhatsapp')],1),_c('a',{staticClass:"m-3 me-0",attrs:{"href":"https://www.linkedin.com/company/mk-co-partner","target":"_blank","rel":"noopener noreferrer"}},[_c('IcoLinkedin')],1)])]),_c('nav',{class:[
      'sticky-top navbar navbar-expand-lg navbar-dark container-fluid custom_transition',
      {
        change_color: _vm.scrollPosition > 50,
        custom_transition: _vm.scrollPosition < 50,
        change_fixed: _vm.scrollPosition > 50,
      } ],staticStyle:{"top":"0px"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navBarMK-CO"}},[_c('ul',{staticClass:"container-fluid navbar-nav text-lg-center d-flex align-items-lg-center align-items-end justify-content-evenly"},[_c('li',{staticClass:"nav-item text-lg-center d-none d-lg-block"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_c('img',{class:[
                'logo-mk-co',
                {
                  change_size: _vm.scrollPosition > 50,
                  custom_transition: _vm.scrollPosition < 50,
                } ],attrs:{"src":require("../../assets/logo/MK-CO-2-W.svg")}})])]),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Découvrir")])],1),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link tapaho",attrs:{"to":("/accompagnement/" + (_vm.services[0] && _vm.services[0].slug))}},[_vm._v("Accompagnement")])],1),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/offre-emploi"}},[_vm._v("Talents")])],1),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link tapaho",attrs:{"to":"/articles"}},[_vm._v("S'informer")])],1),(!_vm.isLoggedIn)?_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/se-connecter"}},[_vm._v("Mon espace")])],1):_vm._e(),(_vm.isLoggedIn)?_c('li',{staticClass:"nav-item text-lg-center"},[_c('div',{staticClass:"dropdown text-light d-flex"},[_c('div',{staticClass:"d-flex align-items-center",attrs:{"data-bs-toggle":"dropdown","aria-expanded":"false","role":"button"}},[_c('span',{},[_vm._v(" "+_vm._s(_vm.me.first_name_visit)+" ")])]),_c('Notification'),_c('ul',{staticClass:"dropdown-menu dropdown-menu-dark"},[_c('li',[_c('a',{staticClass:"dropdown-item cursor-pointer",on:{"click":_vm.redirectToProfil}},[_vm._v("Mon espace")])]),_c('li',[_c('a',{staticClass:"dropdown-item cursor-pointer",on:{"click":_vm.disconnect}},[_vm._v("Se déconnecter")])])])],1)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-brand d-lg-none d-block",attrs:{"href":"#"}},[_c('img',{staticClass:"logo-MK-CO animation-show",attrs:{"src":require("../../assets/logo/MK-CO-2-W.svg"),"width":"50"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navBarMK-CO","aria-controls":"navBarMK-CO","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])}]

export { render, staticRenderFns }