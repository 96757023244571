<template>
  <header class="header h-screen">
    <div
      class="contact px-4 container-fluid d-lg-flex justify-content-between align-items-center p-2 d-none text-muted nav-contact custom_transition"
    >
      <div class="d-flex">
        <small class="col d-flex flex-fill align-items-center">
          <IcoLocalisation />
          <small class="ms-2">II M A35 Antsakaviro 101-Antananarivo</small>
        </small>
        <small class="col d-flex flex-fill align-items-center ps-4">
          <IcoTel />
          <small class="ms-2">+261 32 11 888 95</small>
        </small>
        <small class="col d-flex flex-fill align-items-center ps-4">
          <IcoMail />
          <small class="ms-2">votre-objectif@mk-co.org</small>
        </small>
      </div>
      <small class="d-flex text-end">
        <a
          class="m-3 ms-0"
          href="https://web.facebook.com/MKCOPartner"
          target="_blank"
          rel="noopener noreferrer"
          ><IcoFacebook
        /></a>
        <a
          class="m-3"
          href="https://www.instagram.com/mkcopartner/"
          target="_blank"
          rel="noopener noreferrer"
          ><IcoInstagram
        /></a>
        <a
          class="m-3"
          href="https://api.whatsapp.com/message/SYIFLTHVSMGOI1?autoload=1&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          ><IcoWhatsapp
        /></a>
        <a
          class="m-3 me-0"
          href="https://www.linkedin.com/company/mk-co-partner"
          target="_blank"
          rel="noopener noreferrer"
          ><IcoLinkedin
        /></a>
      </small>
    </div>
    <nav
      :class="[
        'sticky-top navbar navbar-expand-lg navbar-dark container-fluid custom_transition',
        {
          change_color: scrollPosition > 50,
          custom_transition: scrollPosition < 50,
          change_fixed: scrollPosition > 50,
        },
      ]"
      style="top: 0px"
    >
      <a class="navbar-brand d-lg-none d-block" href="#">
        <img
          src="../../assets/logo/MK-CO-2-W.svg"
          width="50"
          class="logo-MK-CO animation-show"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navBarMK-CO"
        aria-controls="navBarMK-CO"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navBarMK-CO">
        <ul
          class="container-fluid navbar-nav text-lg-center d-flex align-items-lg-center align-items-end justify-content-evenly"
        >
          <li class="nav-item text-lg-center d-none d-lg-block">
            <a class="navbar-brand" href="#">
              <img
                src="../../assets/logo/MK-CO-2-W.svg"
                :class="[
                  'logo-mk-co',
                  {
                    change_size: scrollPosition > 50,
                    custom_transition: scrollPosition < 50,
                  },
                ]"
              />
            </a>
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/" class="nav-link">Découvrir</router-link>
          </li>
          <li class="nav-item text-lg-center">
            <router-link
              :to="`/accompagnement/${services[0] && services[0].slug}`"
              class="nav-link tapaho"
              >Accompagnement</router-link
            >
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/offre-emploi" class="nav-link"
              >Talents</router-link
            >
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/articles" class="nav-link tapaho"
              >S'informer</router-link
            >
          </li>
          <li class="nav-item text-lg-center" v-if="!isLoggedIn">
            <router-link to="/se-connecter" class="nav-link"
              >Mon espace</router-link
            >
          </li>
          <li class="nav-item text-lg-center" v-if="isLoggedIn">
            <div class="dropdown text-light d-flex">
              <div
                class="d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                role="button"
              >
                <span class="">
                  {{ me.first_name_visit }}
                </span>
              </div>
              <Notification />
              <ul class="dropdown-menu dropdown-menu-dark">
                <li>
                  <a
                    class="dropdown-item cursor-pointer"
                    @click="redirectToProfil"
                    >Mon espace</a
                  >
                </li>
                <li>
                  <a class="dropdown-item cursor-pointer" @click="disconnect"
                    >Se déconnecter</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { success } from "@/utils/toast";
import IcoLocalisation from "vue-material-design-icons/MapMarker.vue";
import IcoTel from "vue-material-design-icons/Phone.vue";
import IcoMail from "vue-material-design-icons/Email.vue";
import IcoFacebook from "vue-material-design-icons/Facebook.vue";
import IcoInstagram from "vue-material-design-icons/Instagram.vue";
import IcoWhatsapp from "vue-material-design-icons/Whatsapp.vue";
import IcoLinkedin from "vue-material-design-icons/Linkedin.vue";
import Notification from "../../components/public/NotificationVisit.vue";
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";

export default {
  name: "TheHeader",
  components: {
    IcoLocalisation,
    IcoTel,
    IcoMail,
    IcoFacebook,
    IcoInstagram,
    IcoWhatsapp,
    IcoLinkedin,
    Notification,
  },
  data() {
    return {
      scrollPosition: null,
      services: [],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["visiteurStore/isLoggedIn"];
    },
    me() {
      return this.$store.getters["visiteurStore/me"];
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    redirectToProfil() {
      this.$router.push(`/mon-espace/`);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    disconnect() {
      localStorage.removeItem("token");
      this.$store.dispatch("visiteurStore/setVisiteur", {});
      this.$store.dispatch("visiteurStore/setDisconnected");
      this.$router.push(this.$route.query.redirect || "/");
      success("Vous êtes déconnecté");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
.contact {
  height: 3rem;
  background: rgba(11, 11, 11, 0.45);
}

.logo-mk-co {
  width: 70px;
}

#navBarMK-CO a {
  color: $light;
  transition: 250ms;

  &:hover {
    color: darken($primary, $amount: 5);
  }

  &.router-link-exact-active {
    color: $primary;
  }
}

.btn-user {
  transition: 250ms;

  img {
    transition: 500ms;
  }

  &:hover {
    color: $primary;

    img {
      transform: scale(1.2);
    }
  }
}

@media (min-width: 768px) {
  .h-screen {
    height: 100vh;
  }

  .change_fixed {
    position: fixed;
    width: 100%;
  }
  .change_size {
    width: 50px;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    transition: all 500ms ease;
  }
}

.tapaho {
  color: $light !important;
}

@media (max-width: 768px) {
  .header {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
}

// .nav-alt {
//   position: absolute;
//   top: 50rem;
//   bottom: 0;
// }
</style>
