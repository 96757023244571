import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/partenaire";

export const createPartenaire = (data) => {
  return axiosInstanceCollab.post(`${URL}`, data);
};

export const getPartenaire = () => {
  return axiosInstanceCollab.get(`${URL}`);
};

export const getPartenaireByRang = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/partenaireByRang`);
};

export const updatePartenaire = (id, data) => {
  delete data.logo_partenaire;
  return axiosInstanceCollab.put(`${URL}/${id}`, data);
};

export const deletePartenaire = (id) => {
  return axiosInstanceCollab.delete(`${URL}/${id}`);
};

export const switchActivePartenaire = (switchData) => {
  const data = {
    isActive: !switchData.value,
  };
  return axiosInstanceCollab.put(
    `${URL}/switch-active/${switchData.id_partenaire}`,
    data
  );
};
