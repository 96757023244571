<template>
  <section
    class="p-5 d-flex flex-column align-items-center justify-content-center bg-light"
  >
    <h2 id="service">Nos accompagnements</h2>
    <p>
      Exposez nous vos objectifs et ENSEMBLE, nous déterminerons les étapes et
      la voie pour les atteindre
    </p>
    <article class="mt-3 w-100">
      <div class="container">
        <div class="row justify-content-center row-cols-lg-4 row-cols-1 g-5">
          <div
            class="col"
            v-for="(service, i) in services"
            :key="i"
            @click="redirectToService(service.slug)"
          >
            <div class="card bg-light border-0">
              <div class="card-content-alt position-relative">
                <!-- style="
                    filter: invert(5%) sepia(78%) saturate(6858%)
                      hue-rotate(276deg) brightness(97%) contrast(101%);
                  " -->
                <img
                  :src="URL_IMAGE + service.logo_service"
                  class="position-absolute"
                />
                <img :src="service.logo_service" class="position-absolute" />

                <!-- <img :src="service.img" class="card-img" alt="..." />
                <div class="card-illustration rounded-end">
                  <img :src="service.illus" />
                </div> -->
              </div>
              <div class="card-body p-0 pt-3">
                <h5 class="card-title fw-bold">{{ service.title_service }}</h5>
                <p class="card-text">
                  <!-- {{ service.text }} -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";
import { URL_IMAGE } from "../../configs/index";

export default {
  name: "ServiceMK-CO",
  data() {
    return {
      URL_IMAGE: URL_IMAGE,
      services: [],
    };
  },
  mounted() {
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    redirectToService(slug) {
      this.$router.push(`/accompagnement/${slug}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.card {
  // width: 100%;
  // height: 100%;

  .card-title {
    transition: 250ms;
  }

  &:hover {
    cursor: pointer;

    // .card-illustration {
    //   opacity: 1;
    //   width: 50%;
    // }

    .card-content-alt {
      animation: animation-blink-2 1s alternate infinite;
    }

    .card-title {
      color: $primary;
    }

    img {
      // &:first-child {
      //   opacity: 0;
      // }

      // &:last-child {
      //   opacity: 1;
      // }
      filter: invert(68%) sepia(91%) saturate(492%) brightness(105%)
        contrast(103%);
    }
  }
}

.card-content-alt {
  width: 3rem;
  height: 3rem;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child,
    &:last-child {
      transition: 500ms;
    }

    &:last-child {
      opacity: 0;
    }
  }
}

// .card-content {
//   position: relative;
//   width: 100%;
//   height: 12.5rem;

//   .card-img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

//   .card-illustration {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     right: 0;
//     top: 0;
//     opacity: 0;
//     width: 0;
//     height: 100%;
//     // transform: scale(0);
//     background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
//     // background-color: $primary;
//     transition: 250ms;

//     img {
//       width: 50%;
//     }
//   }
// }
</style>
