<template>
  <article class="container-fluid mb-4">
    <div
      id="carouselArticle"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="d-flex align-items-stretch justify-content-evenly">
            <div class="card border-0 mx-3">
              <img
                src="../../assets/img/bg-img-2.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title text-primary">Lorem ipsum dolor sit.</h5>
                <p class="card-text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ex
                  laudantium quam porro dicta?
                </p>
                <p class="card-text text-end">
                  <small class="text-muted">30/01/22</small>
                </p>
              </div>
            </div>
            <div class="card border-0 mx-3">
              <img
                src="../../assets/img/bg-img-4.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title text-primary">Lorem ipsum dolor sit.</h5>
                <p class="card-text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit,
                  odio.
                </p>
                <p class="card-text text-end">
                  <small class="text-muted">30/01/22</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-flex align-items-stretch justify-content-evenly">
            <div class="card border-0 mx-3">
              <img
                src="../../assets/img/bg-img-2.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title text-primary">Lorem ipsum dolor sit.</h5>
                <p class="card-text">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. At
                  repudiandae quas sequi.
                </p>
                <p class="card-text text-end">
                  <small class="text-muted">30/01/22</small>
                </p>
              </div>
            </div>
            <div class="card border-0 mx-3">
              <img
                src="../../assets/img/bg-img-5.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title text-primary">Lorem ipsum dolor sit.</h5>
                <p class="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui,
                  eligendi.
                </p>
                <p class="card-text text-end">
                  <small class="text-muted">30/01/22</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="carousel-item">
          <div class="card border-0">
            <img
              src="../../assets/img/bg-img-4.jpg"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-primary">Lorem ipsum dolor sit.</h5>
              <p class="card-text">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
              <p class="card-text text-end">
                <small class="text-muted">30/01/22</small>
              </p>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselArticle"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselArticle"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
    </div>
  </article>
</template>

<script>
export default {
  name: "CarouselVisiteur",
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 500px;
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
