import axios from "axios";
import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/FAQ";

const Q_URL = BASE_URL + "/faq";

export const sendFAQ = (data) => {
  return axios.post(`${URL}`, data);
};

export const getAllFAQ = () => {
  return axiosInstanceCollab.get(`${Q_URL}`);
};

export const getFaqById = (id) => {
  return axiosInstanceCollab.get(`${Q_URL}/${id}`);
};

export const updateFAQ = (id, data) => {
  return axiosInstanceCollab.put(`${Q_URL}/${id}`, data);
};
