import { render, staticRenderFns } from "./LayoutAccueil.vue?vue&type=template&id=678ee4ee&scoped=true&"
import script from "./LayoutAccueil.vue?vue&type=script&lang=js&"
export * from "./LayoutAccueil.vue?vue&type=script&lang=js&"
import style0 from "./LayoutAccueil.vue?vue&type=style&index=0&id=678ee4ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678ee4ee",
  null
  
)

export default component.exports