<template>
  <LayoutAccueil>
    <Avantage />
    <Service />
    <Article class="d-none" />
    <Partenaire />
    <Reference />
    <Rejoindre />
    <FAQ />
  </LayoutAccueil>
</template>

<script>
import LayoutAccueil from "../../layout/LayoutAccueil.vue";
import Article from "../../components/accueil/Article.vue";
import Avantage from "../../components/accueil/Avantage.vue";
import FAQ from "../../components/accueil/FAQ.vue";
import Partenaire from "../../components/accueil/Partenaire.vue";
import Reference from "../../components/accueil/Reference.vue";
import Rejoindre from "../../components/accueil/Rejoindre.vue";
import Service from "../../components/accueil/Service.vue";

export default {
  name: "Accueil",
  components: {
    LayoutAccueil,
    Article,
    Avantage,
    FAQ,
    Partenaire,
    Reference,
    Rejoindre,
    Service,
  },
};
</script>

<style lang="scss" scoped></style>
