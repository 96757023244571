<template>
  <div class="background-accueil">
    <HeaderAccueil />
    <Presentation />

    <slot />
    <TheFooter />
  </div>
</template>

<script>
import HeaderAccueil from "../components/public/HeaderAccueil.vue";
import Presentation from "../components/accueil/Presentation.vue";
import TheFooter from "../components/public/TheFooter.vue";

export default {
  name: "LayoutAccueil",
  components: { HeaderAccueil, Presentation, TheFooter },
};
</script>

<style lang="scss" scoped>
.background-accueil {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../assets/img/img-presentation.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
