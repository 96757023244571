<template>
  <!-- <section
    class="p-5 d-flex flex-column align-items-center justify-content-center bg-primary"
  > -->
  <section
    class="p-5 d-flex flex-column align-items-center justify-content-center bg-avantage"
  >
    <h2 v-scroll-reveal.reset class="text-dark">Nos particularités</h2>
    <article class="mt-3">
      <div
        class="row g-lg-0 g-5 row-cols-lg-4 row-cols-1 g-0 d-flex flex-lg-nowrap align-items-stretch justify-content-center"
      >
        <!-- <div class="col img-illus d-lg-block d-none">
          <img src="../../assets/illustration/Illus-Avantage-3.svg" alt="" />
        </div> -->
        <div class="col">
          <div class="card shadow bg-dark text-light border-0 h-100">
            <img
              :src="URL_IMAGE + particularite1.img_particularite"
              class="card-img-top"
              :class="
                particularite1.img_particularite ? 'd-lg-block' : 'd-none'
              "
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-primary">
                {{ particularite1.titre_particularite }}
              </h5>
              <p class="card-text d-flex">
                {{ particularite1.description_particularite }}
              </p>
            </div>
          </div>
        </div>
        <div class="col img-illus d-lg-block d-none">
          <img src="../../assets/illustration/Illus-Avantage-1.svg" alt="" />
        </div>
        <div class="col">
          <div class="card shadow bg-dark text-light border-0 h-100">
            <img
              :src="URL_IMAGE + particularite2.img_particularite"
              class="card-img-top"
              :class="
                particularite2.img_particularite ? 'd-lg-block' : 'd-none'
              "
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-primary">
                {{ particularite2.titre_particularite }}
              </h5>
              <p class="card-text d-flex">
                {{ particularite2.description_particularite }}
              </p>
            </div>
          </div>
        </div>
        <div class="col img-illus d-lg-block d-none">
          <img src="../../assets/illustration/Illus-Avantage-2.svg" alt="" />
        </div>
        <div class="col">
          <div class="card shadow bg-dark text-light border-0 h-100">
            <img
              :src="URL_IMAGE + particularite3.img_particularite"
              class="card-img-top"
              :class="
                particularite3.img_particularite ? 'd-lg-block' : 'd-none'
              "
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-primary">
                {{ particularite3.titre_particularite }}
              </h5>
              <p class="card-text d-flex">
                {{ particularite3.description_particularite }}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col img-illus d-lg-block d-none">
          <img src="../../assets/illustration/Illus-Avantage-4.svg" alt="" />
        </div> -->
        <!-- <div class="col" v-for="(avantage, i) in avantages" :key="i">
          <div class="card border-0 h-100">
            <img :src="avantage.img" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title text-primary">{{ avantage.title }}</h5>
              <p class="card-text d-flex">
                {{ avantage.text }}
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </article>
  </section>
</template>

<script>
import { getParticulariteById } from "../../api/apiParticularite";
import { URL_IMAGE } from "../../configs/index";

export default {
  name: "AvantageMK-CO",
  data() {
    return {
      URL_IMAGE: URL_IMAGE,
      particularite1: {},
      particularite2: {},
      particularite3: {},
    };
  },
  mounted() {
    getParticulariteById(1).then((result) => {
      this.particularite1 = result.data;
    });
    getParticulariteById(2).then((result) => {
      this.particularite2 = result.data;
    });
    getParticulariteById(3).then((result) => {
      this.particularite3 = result.data;
    });
  },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/_variables.scss";

// section {
//   background-color: $primary;
// }

.card {
  transition: 250ms;

  &:hover {
    transform: scale(1.05);
  }
}

.img-illus {
  flex: auto;
  align-self: center;
  justify-self: center;

  img {
    width: 100%;
  }
}

.bg-avantage {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../../assets/img/bg-img-11.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
