<template>
  <section class="d-flex flex-column presentation">
    <div
      class="d-flex flex-column pt-lg-5 p-0 justify-content-evenly align-items-center"
    >
      <div
        class="container mt-5 d-flex justify-content-evenly align-items-center"
      >
        <img
          v-scroll-reveal.reset="{ delay: 200 }"
          src="../../assets/logo/MK-CO-2-TW.svg"
          class="logo-MK-CO"
        />
      </div>
      <p
        v-scroll-reveal.reset="{ delay: 400 }"
        class="text-center text-light mx-3 fs-4"
      >
        LE partenaire pour l’atteinte de VOS objectifs. Commençons ensemble à
        les déterminer et les accomplir…
      </p>
      <div
        v-scroll-reveal.reset="{
          distance: '30px',
        }"
      >
        <a
          type="button"
          class="btn btn-primary shadow btn-lg m-5 rounded-pill px-4 animation-show animation-blink"
          @click="scroll()"
        >
          C’est parti <IcoPlus />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import IcoPlus from "vue-material-design-icons/DotsHorizontal.vue";

export default {
  name: "PresentationMK-CO",
  components: {
    IcoPlus,
  },
  data() {
    return {
      scrollPosition: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["visiteurStore/isLoggedIn"];
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scroll() {
      document.getElementById("service").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  height: 100vh;
}

.nav-contact {
  position: fixed;
  bottom: 0;
}

.logo-MK-CO {
  width: 50%;
}

@media (min-width: 768px) {
  .presentation {
    position: absolute;
    top: 10%;
    width: 100%;
  }
}
</style>
