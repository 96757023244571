<template>
  <div
    class="sub container-fluid m-0 p-0 d-flex flex-lg-row flex-column bg-light text-dark"
  >
    <div
      class="sub-1 col d-flex flex-column justify-content-center align-items-center"
    >
      <img src="../../assets/illustration/FAQ.svg" alt="" srcset="" />
      <article class="container-fluid p-4">
        <div class="accordion accordion-flush text-dark" id="accordionFAQ">
          <div
            class="accordion-item"
            v-for="item in questions"
            :key="item.id_faq"
          >
            <h2 class="accordion-header" id="question-1">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#answer-${item.id_faq}`"
                aria-expanded="false"
              >
                {{ item.title_faq }}
              </button>
            </h2>
            <div
              :id="`answer-${item.id_faq}`"
              class="accordion-collapse collapse"
            >
              <div class="accordion-body">
                {{ item.content_faq }}
              </div>
            </div>
          </div>
          <!-- <div class="accordion-item">
            <h2 class="accordion-header" id="question-4">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#answer-4"
                aria-expanded="false"
              >
                Question #4
              </button>
            </h2>
            <div id="answer-4" class="accordion-collapse collapse">
              <div class="accordion-body">
                De, quod praesentium fugit ullam quis eum quisquam harum
                blanditiis excepturi! Quisquam minus dignissimos distinctio
                porro quis rerum libero necessitatibus, neque, modi assumenda
                quod.
              </div>
            </div>
          </div> -->
        </div>
      </article>
    </div>
    <div class="sub-2 col p-3">
      <h2 class="text-center mb-3 text-primary">Vous avez la parole ici !</h2>
      <form
        class="row g-3"
        @submit.prevent="submit"
        formnovalidate="formnovalidate"
      >
        <div class="col-md-6">
          <label for="inputName" class="form-label">Nom complet*</label>
          <input
            type="text"
            class="form-control"
            id="inputName"
            v-model="form.full_name_faq"
            :class="{
              'is-invalid': submitted && $v.form.full_name_faq.$error,
            }"
          />
          <div
            v-if="submitted && !$v.form.full_name_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre nom complet
          </div>
        </div>
        <div class="col-md-6">
          <label for="inputTel" class="form-label">Téléphone*</label>
          <input
            type="tel"
            class="form-control"
            id="inputTel"
            v-model="form.tel_faq"
            :class="{
              'is-invalid': submitted && $v.form.tel_faq.$error,
            }"
          />
          <div
            v-if="submitted && !$v.form.tel_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre numéro de téléphone
          </div>
        </div>
        <div class="col-12">
          <label for="inputMail1" class="form-label">Mail*</label>
          <input
            type="email"
            class="form-control"
            id="inputMail1"
            v-model="form.email_faq"
            :class="{ 'is-invalid': submitted && $v.form.email_faq.$error }"
          />
          <div
            v-if="submitted && $v.form.email_faq.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.email_faq.required"
              >Veuillez insérer votre adresse mail</span
            >
            <span v-if="!$v.form.email_faq.email"
              >Veuillez insérer une adresse mail valide</span
            >
          </div>
        </div>
        <div class="col-12">
          <label for="inputMail1" class="form-label">Objet*</label>
          <input
            type="text"
            class="form-control"
            id="inputMail1"
            v-model="form.objets_faq"
            :class="{
              'is-invalid': submitted && $v.form.objets_faq.$error,
            }"
          />
          <div
            v-if="submitted && !$v.form.objets_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer l'objet
          </div>
        </div>
        <div class="col-12">
          <label for="inputMessage" class="form-label">Votre message*</label>
          <textarea
            class="form-control"
            id="inputMessage"
            v-model="form.message_faq"
            rows="3"
            :class="{
              'is-invalid': submitted && $v.form.message_faq.$error,
            }"
          ></textarea>
          <div
            v-if="submitted && !$v.form.message_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre message ici
          </div>
        </div>

        <div class="col-12 mb-2 mt-4">
          <button v-if="loading" class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button>
          <button
            v-else
            type="submit"
            class="btn btn-primary"
            formnovalidate="formnovalidate"
          >
            Envoyer
            <!-- <IcoSend /> -->
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import IcoSend from "vue-material-design-icons/Send.vue";
import { sendFAQ, getAllFAQ } from "../../api/apiFAQ";
import { success } from "../../utils/toast";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "FAQ",
  components: {
    // IcoSend,
  },
  data() {
    return {
      form: {
        full_name_faq: "",
        tel_faq: "",
        email_faq: "",
        objets_faq: "",
        message_faq: "",
      },
      loading: false,
      submitted: false,
      questions: [],
    };
  },
  validations: {
    form: {
      full_name_faq: { required },
      tel_faq: { required },
      email_faq: { required, email },
      objets_faq: { required },
      message_faq: { required },
    },
  },
  mounted() {
    getAllFAQ().then((result) => {
      this.questions = result.data;
    });
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      sendFAQ(this.form).then(() => {
        this.loading = false;
        success("Votre question a été envoyé");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sub {
  height: auto;
}

img {
  width: 40%;
}
</style>
