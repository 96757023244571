import { render, staticRenderFns } from "./Avantage.vue?vue&type=template&id=481f7921&scoped=true&"
import script from "./Avantage.vue?vue&type=script&lang=js&"
export * from "./Avantage.vue?vue&type=script&lang=js&"
import style0 from "./Avantage.vue?vue&type=style&index=0&id=481f7921&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481f7921",
  null
  
)

export default component.exports