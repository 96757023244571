<template>
  <section
    class="d-flex flex-column justify-content-evenly align-items-center p-3 bg-dark text-light"
  >
    <h2 class="text-primary">Nous collaborons avec eux :</h2>
    <p>
      Afin de toujours proposer le meilleur service d’accompagnement
      d’entreprise, dans différents domaines d’expertise, MK-CO travaille aussi
      avec plusieurs partenaires.
    </p>
    <div class="carousel-partenaires">
      <VueSlickCarousel v-bind="settings" v-if="partenaires.length">
        <div
          v-for="(item, i) in partenaires"
          :key="i"
          class="carousel-elements"
        >
          <a :href="item.url_partenaire" target="_blank">
            <img :src="URL_IMAGE + item.logo_partenaire"
          /></a>
        </div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import { getPartenaireByRang } from "../../api/partenaire";
import { URL_IMAGE } from "../../configs";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "PartenaireMK-CO",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        dots: false,
        infinite: true,
        initialSlide: 2,
        arrow: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      URL_IMAGE: URL_IMAGE,
      partenaires: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getPartenaireByRang().then((result) => {
        this.partenaires = result.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 5rem;
}

.slick-next::before {
  background: url("../../assets/logo/vector-r.svg") no-repeat;
  content: "";
  display: block;
  height: 40px;
  width: 65px;
  margin-left: 5px;
  margin-top: -15px;
}
.slick-prev::before {
  background: url("../../assets/logo/vector-l.svg") no-repeat;
  content: "";
  display: block;
  height: 40px;
  width: 65px;
  margin-left: -5px;
  margin-top: -15px;
}

.carousel-partenaires {
  margin: 0 15%;
  width: 80%;
}

.carousel-partenaires div {
  //display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-evenly;
  margin: 0.5rem 0;
}

.carousel-partenaires div img {
  width: 7.5rem;
  margin: auto;
}

@media (max-width: 800px) {
  .carousel-partenaires {
    margin: 0 10%;
  }
}
</style>
